import React from "react";
import PropTypes from "prop-types";

import ComponentMissing from "../../Debug/ComponentMissing";
// eslint-disable-next-line import/no-cycle
import GridRow from "./GridRow";
import Checkbox from "./Checkbox";
import RadioButton from "./RadioButton";
import Text from "./Text";
import Telephone from "./Telephone";
import Email from "./Email";
import TextArea from "./TextArea";
import SingleSelect from "./SingleSelect";
import MultiSelect from "./MultiSelect";
import Honeypot from "./Honeypot";
import Hidden from "./Hidden";
import Submit from "./Submit";
import StaticText from "./StaticText";

const FormElementSwitcher = ({
  item,
  formData,
  validationErrors,
  onChangeHandler,
  toggleableFields,
  animation,
}) => {
  const switchFormElement = () => {
    const toggleableField = toggleableFields.find((field) => field.target === item.identifier);

    if (toggleableField && toggleableField.hideField) {
      return null;
    }

    switch (item.type) {
      case "GridRow":
        return (
          <GridRow
            formData={formData}
            validationErrors={validationErrors}
            onChangeHandler={onChangeHandler}
            elements={item.elements}
            toggleableFields={toggleableFields}
          />
        );
      case "Checkbox":
        return (
          <Checkbox
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            description={item.properties.elementDescription}
          />
        );
      case "RadioButton":
        return (
          <RadioButton
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            options={item.properties.options}
          />
        );
      case "Text":
        return (
          <Text
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            placeholder={item.properties.fluidAdditionalAttributes?.placeholder}
          />
        );
      case "Telephone":
        return (
          <Telephone
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            placeholder={item.properties.fluidAdditionalAttributes?.placeholder}
          />
        );
      case "Email":
        return (
          <Email
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            placeholder={item.properties.fluidAdditionalAttributes?.placeholder}
          />
        );
      case "Textarea":
        return (
          <TextArea
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            placeholder={item.properties.fluidAdditionalAttributes?.placeholder}
          />
        );
      case "SingleSelect":
        return (
          <SingleSelect
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            defaultValue={item.properties.prependOptionLabel}
            options={item.properties.options}
          />
        );
      case "MultiSelect":
        return (
          <MultiSelect
            id={item.identifier}
            label={item.label}
            formData={formData}
            validationErrors={validationErrors[item.identifier]}
            onChangeHandler={onChangeHandler}
            defaultValue={item.properties.prependOptionLabel}
            options={item.properties.options}
          />
        );
      case "Honeypot":
        return (
          <Honeypot id={item.identifier} formData={formData} onChangeHandler={onChangeHandler} />
        );
      case "Hidden":
        return <Hidden id={item.identifier} defaultValue={item.defaultValue ?? ""} />;
      case "submit":
        return <Submit id={item.identifier} label={item.label} animation={animation} />;
      case "StaticText":
        return <StaticText id={item.identifier} label={item.label} animation={animation} />;
      default:
        return <ComponentMissing type="FormElement" subType={item.type} />;
    }
  };

  return switchFormElement();
};

FormElementSwitcher.propTypes = {
  item: PropTypes.instanceOf(Object),
  formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.instanceOf(Object),
  onChangeHandler: PropTypes.func,
  toggleableFields: PropTypes.instanceOf(Array),
  animation: PropTypes.instanceOf(Object),
};

export default FormElementSwitcher;
