import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const HorizontalFeatures = ({
  properties: { header, headerType, headerDisplayStyle, subline, features, animation },
}) => {
  const renderFeatures = useCallback(() => {
    return features.map((feature) => {
      const { cols_xs: colXs, cols_sm: colSm, cols_md: colMd, cols_lg: colLg } = feature;

      let featureItem = (
        <div className="card w-100">
          <div className="card-body">
            {(feature.image[0] || feature.subheader || feature.headline) && (
              <div className="mb-xsmall">
                <div className="card-horizontal">
                  <div className="card-image">
                    <Image image={feature.image[0]} animation={animation.image.default} />
                  </div>

                  <div className={`${feature.image[0] ? "card-info ms-4" : "card-info"}`.trim()}>
                    <Preline preline={feature.subheader} additionalClasses="color-static" />

                    <Headline
                      headerType="h5"
                      headline={feature.headline}
                      additionalClasses="color-static"
                    />
                  </div>
                </div>
              </div>
            )}

            <Text textType="html" text={feature.teaser} additionalClasses="color-static" />

            {feature.link && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={feature.link_type}
                    link={feature.link}
                    additionalClasses="color-static"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );

      if (feature.cardLink) {
        featureItem = (
          <a
            href={feature.cardLink.url}
            target="_blank"
            rel="noreferrer"
            className="d-flex card-link w-100"
          >
            <div className="card w-100">
              <div className="card-body">
                {(feature.image[0] || feature.subheader || feature.headline) && (
                  <div className="mb-xsmall">
                    <div className="card-horizontal">
                      <div className="card-image">
                        <Image image={feature.image[0]} animation={animation.image.default} />
                      </div>

                      <div
                        className={`${feature.image[0] ? "card-info ms-4" : "card-info"}`.trim()}
                      >
                        <Preline preline={feature.subheader} additionalClasses="color-static" />

                        <Headline
                          headerType="h5"
                          headline={feature.headline}
                          additionalClasses="color-static"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <Text textType="html" text={feature.teaser} additionalClasses="color-static" />
              </div>
            </div>
          </a>
        );
      }

      return (
        <div
          key={`key_${feature.headline || Math.random()}`}
          className={`col-xs-${colXs} col-sm-${colSm} col-md-${colMd} col-lg-${colLg} d-flex`}
        >
          {featureItem}
        </div>
      );
    });
  }, [features, animation]);

  return (
    <div className="container features horizontal">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {features.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center g-4">{renderFeatures()}</div>
        </div>
      )}
    </div>
  );
};

HorizontalFeatures.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default HorizontalFeatures;
