import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const HorizontalText = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    bodytext,
    cols,
    alignment,
    colProportions,
    mirrored,
    animation,
  },
}) => {
  const proportion = useRef(colProportions ? colProportions.split("-") : [6, 6]);

  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const getTextContainer = useCallback(
    (proportionIndex) => {
      return (
        <div className={`col-md-${proportion[proportionIndex]} text-md-end`}>
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      );
    },
    [header, headerType, headerDisplayStyle, subline, proportion],
  );

  const getLinksContainer = useCallback(
    (proportionIndex) => {
      return (
        <div className={`col-md-${proportion[proportionIndex]}`}>
          <Text textType="html" text={bodytext} />

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      );
    },
    [bodytext, headerLink, headerLinkType, secondaryLink, secondaryLinkType, animation, proportion],
  );

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse mirrored">
          {getTextContainer(1)}
          {getLinksContainer(0)}
        </div>
      );
    }

    return (
      <div className="row">
        {getTextContainer(0)}
        {getLinksContainer(1)}
      </div>
    );
  }, [mirrored, getTextContainer, getLinksContainer]);

  return (
    <div className="container text horizontal">
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>{getContent()}</div>
      </div>
    </div>
  );
};

HorizontalText.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default HorizontalText;
