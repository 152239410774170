import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const VerticalNewsListItem = ({ news, linkType, linkTitle, hideDate, animation }) => {
  const inlineStyles = {};

  return (
    <div className="col d-flex">
      <div className="card w-100">
        <div className="card-image">
          <Image
            image={{
              publicUrl: news.media?.[0]?.images.listViewImage.publicUrl,
              properties: { alternative: news.media?.[0]?.properties.alternative },
            }}
            style={inlineStyles}
          />
        </div>

        <div className="card-body">
          {!hideDate && <h6 className="news-list-date color-static">{news.datetime}</h6>}

          {news.slug && (
            <NavLink to={news.slug}>
              <Headline headerType="h4" headline={news.title} additionalClasses="color-static" />
            </NavLink>
          )}

          {news.teaser && (
            <div className="mt-xsmall">
              <Text
                textType="text"
                text={`${news.teaser.slice(0, 350)}...`}
                additionalClasses="color-static"
              />
            </div>
          )}

          {news.slug && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={linkType}
                  link={{ publicUrl: news.slug, title: linkTitle }}
                  additionalClasses="color-static"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

VerticalNewsListItem.propTypes = {
  news: PropTypes.instanceOf(Object),
  linkType: PropTypes.string,
  linkTitle: PropTypes.string,
  hideDate: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default VerticalNewsListItem;
