/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import MenuPagesSwitcher from "./MenuPages/MenuPagesSwitcher";
import MenuSubPagesSwitcher from "./MenuSubPages/MenuSubPagesSwitcher";
import MenuSectionSwitcher from "./MenuSection/MenuSectionSwitcher";
import MenuPagesWithSearchSwitcher from "./MenuPagesWithSearch/MenuPagesWithSearchSwitcher";
// eslint-disable-next-line max-len
import MenuSubPagesWithSearchSwitcher from "./MenuSubPagesWithSearch/MenuSubPagesWithSearchSwitcher";
import ComponentMissing from "../Debug/ComponentMissing";

const NavigationSwitcher = ({ type, appearance, content, languages, activeLanguage }) => {
  const properties = {
    layout: appearance.layout,
    primaryLink: content.headerLink,
    primaryLinkType: content.headerLinkType,
    secondaryLink: content.secondaryLink,
    secondaryLinkType: content.secondaryLinkType,
    logo: content.logo || appearance.logo,
    topLinks: content.topLinks,
    searchLabel: content.searchLabel,
    searchListUrl: content.searchListUrl,
    searchPlaceholder: content.searchPlaceholder,
    navigation: content.menu,
    languages,
    activeLanguage,
  };

  function switchNavigation() {
    switch (type) {
      case "menu_pages":
        return <MenuPagesSwitcher properties={properties} />;
      case "menu_subpages":
        return <MenuSubPagesSwitcher properties={properties} />;
      case "menu_section":
        return <MenuSectionSwitcher properties={properties} />;
      case "menu_pages_with_search":
        return <MenuPagesWithSearchSwitcher properties={properties} />;
      case "menu_subpages_with_search":
        return <MenuSubPagesWithSearchSwitcher properties={properties} />;
      default:
        return <ComponentMissing type="Navigation" subType={type} />;
    }
  }

  return <section id="navigation">{switchNavigation()}</section>;
};

NavigationSwitcher.propTypes = {
  type: PropTypes.string,
  appearance: PropTypes.instanceOf(Object),
  content: PropTypes.instanceOf(Object),
  languages: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
  activeLanguage: PropTypes.instanceOf(Object),
};

export default NavigationSwitcher;
