/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Select, { components } from "react-select";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { Collapse } from "bootstrap";

import TranslationContext from "../../../Context/Translation";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import Headline from "../../Atoms/Headline";

import { ReactComponent as SearchIcon } from "../../../Images/svg/search.svg";

const SecondaryMenuSubPagesWithSearch = ({
  properties: {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    searchLabel,
    searchListUrl,
    searchPlaceholder,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const [language, setLanguage] = useStateWithCallbackLazy({
    label: activeLanguage.twoLetterIsoCode.toUpperCase(),
    value: activeLanguage.twoLetterIsoCode,
  });

  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");

  const collapse = useRef(null);

  const onSearchChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();

    history.push({
      pathname: "/s",
      search: `?query=${searchValue}`,
      state: {
        searchListUrl: searchListUrl.url,
        searchValue,
      },
    });

    setTimeout(() => {
      collapse.current.hide();
    }, 500);
  };

  useEffect(() => {
    // without this check it will initially fire twice: first time on initial load when
    // translation.length is 0 and second time when translation will be set in Context
    // also we ensure this way that activeTranslation and newTranslation below will not be undefined
    if (translation.length) {
      const activeTranslation = translation.find((lang) => lang.active === 1);
      const newTranslation = translation.find((lang) => lang.twoLetterIsoCode === language.value);
      // prevention of 2 pushes on language change - first when the language state is updated
      // and second when the Context is updated by the new page translation data (after push)
      // + prevention of push from / to /landing as side effect
      if (activeTranslation.link !== newTranslation.link) {
        // when we are moving between pages with the same language everything is ok
        // when we are changing language using select everything is ok
        // BUT when we are on /en/landing page and clicking on logo ("/" route)
        // there will be a problem: we do not update the language value in our state,
        // so it will be 'en' in selector and 'de' in our context, which will lead to
        // multiple puhses from page to page. To prevent that we need to update our
        // language value in state, BUT there is a catch - we need to fire push only
        // AFTER the new state will be setup, that's why we use a custom hook here,
        // because setters are asynchronous
        setLanguage(
          {
            label: activeTranslation.twoLetterIsoCode.toUpperCase(),
            value: activeTranslation.twoLetterIsoCode,
          },
          // this callback will fire after the state will be updated, receives new value
          // as a parameter
          () => {
            // for a proper work of hyphens: auto
            document.documentElement.setAttribute("lang", newTranslation.twoLetterIsoCode);

            history.push(newTranslation.link);
          },
        );
      }
    }
  }, [language, translation, history, setLanguage]);

  useEffect(() => {
    const navToggler = document.getElementById("navbarNav");

    if (navToggler) {
      navToggler.addEventListener("show.bs.collapse", () => {
        document.body.classList.add("overflow-hidden");

        // additionally hide search collapse
        collapse.current.hide();
      });

      navToggler.addEventListener("hide.bs.collapse", () => {
        document.body.classList.remove("overflow-hidden");

        // additionally hide search collapse
        collapse.current.hide();
      });
    }
  }, []);

  useEffect(() => {
    const searchCollapse = document.getElementById("search");
    const navToggler = document.getElementById("navbarNav");

    collapse.current = new Collapse(searchCollapse, { toggle: false });

    // additionally hide nav collapse
    searchCollapse.addEventListener("show.bs.collapse", () => {
      Collapse.getInstance(navToggler)?.hide();
    });
  }, []);

  useEffect(() => {
    const onMouseIn = (e) => {
      const toggler = e.target.querySelector(".dropdown-toggle");
      const menu = e.target.querySelector(".dropdown-menu");

      if (toggler && window.innerWidth > 1399) {
        if (menu && !menu.classList.contains("show")) {
          toggler.click();
        }
      }
    };

    const onMouseOut = (e) => {
      const toggler = e.target.querySelector(".dropdown-toggle");
      const menu = e.target.querySelector(".dropdown-menu");

      if (toggler && window.innerWidth > 1399) {
        if (menu && menu.classList.contains("show")) {
          toggler.click();
        }
      }
    };

    const navDropdowns = document.querySelectorAll(".navbar-nav .nav-item.dropdown");

    navDropdowns.forEach((el) => {
      el.addEventListener("mouseenter", onMouseIn);
      el.addEventListener("mouseleave", onMouseOut);
    });

    return () => {
      navDropdowns.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseIn);
        el.removeEventListener("mouseleave", onMouseOut);
      });
    };
  }, []);

  const renderDropdownChild = (child) => {
    return (
      <li key={`key_${child.link || Math.random()}`}>
        <NavLink className="dropdown-item" to={child.link}>
          <span className="dropdown-text">{child.title}</span>
        </NavLink>
      </li>
    );
  };

  const onLanguageChanged = (langObj) => {
    // problem here is, translation path for current page is written in
    // pageContainer not in Navigation
    // not sure, how to solve this in the right way

    setLanguage(langObj);
  };

  const renderLanguageswitch = () => {
    if (languages.length > 1) {
      const styles = {
        indicatorSeparator: () => {},
        control: (base) => ({
          ...base,
          fontSize: 14,
          fontWeight: 600,
          // borderRadius: "24px",
          borderRadius: "0",
          borderColor: "transparent",
          borderWidth: "0",
          backgroundColor: "transparent",
          // paddingLeft: "0.5rem",
          // paddingRight: "0.5rem",
          paddingLeft: "0",
          paddingRight: "0",
          paddingTop: "0",
          paddingBottom: "0",
          boxShadow: "none",
          cursor: "pointer",
          color: "#60605a",
        }),
        singleValue: (base) => ({
          ...base,
          color: "#60605a",
          fontWeight: 500,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: 0,
          paddingRight: 3,
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          padding: 0,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 16,
          fontWeight: 300,
          top: 38,
          left: 2,
        }),
        option: (base, { isSelected }) => ({
          ...base,
          cursor: "pointer",
          fontWeight: isSelected ? 700 : 300,
          paddingTop: 12,
          paddingBottom: 12,
          backgroundColor: "#ffffff !important",
          color: "#60605a !important",

          ".option-text": {
            position: "relative",
          },

          "&:hover": {
            ".option-text::after": {
              content: '""',
              display: "block",
              height: 1,
              position: "absolute",
              bottom: -5,
              left: 0,
              right: 0,
              backgroundColor: "#60605a",
            },
          },
        }),
      };

      const options = languages.map((lang) => {
        return {
          label: lang.twoLetterIsoCode.toUpperCase(),
          value: lang.twoLetterIsoCode,
        };
      });

      return (
        <div className="language">
          <Select
            aria-label="Language Select"
            styles={styles}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: (props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <components.DropdownIndicator {...props}>
                  <svg
                    id="SVGDoc"
                    width="16"
                    height="9"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 13 7"
                  >
                    <g>
                      <g>
                        <title>chevron-down</title>
                        <path
                          // eslint-disable-next-line max-len
                          d="M1.04003,0.61695c0.08061,-0.08081 0.19005,-0.12623 0.30419,-0.12623c0.11414,0 0.22358,0.04542 0.30419,0.12623l4.85157,4.85243v0l4.85157,-4.85243c0.168,-0.168 0.44038,-0.168 0.60838,0c0.168,0.168 0.168,0.44038 0,0.60838l-5.15577,5.15576c-0.08061,0.08081 -0.19005,0.12623 -0.30419,0.12623c-0.11414,0 -0.22358,-0.04542 -0.30419,-0.12623l-5.15577,-5.15577c-0.08081,-0.08061 -0.12623,-0.19005 -0.12623,-0.30419c0,-0.11414 0.04542,-0.22358 0.12623,-0.30419z"
                          fill="#60605a"
                          fillOpacity="1"
                        />
                      </g>
                    </g>
                  </svg>
                </components.DropdownIndicator>
              ),
              Option: ({ children, ...props }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <components.Option {...props}>
                  <span className="option-text">{children}</span>
                </components.Option>
              ),
            }}
            isSearchable={false}
            options={options}
            value={language}
            onChange={onLanguageChanged}
          />
        </div>
      );
    }

    return <></>;
  };

  const renderDropdown = (naviagationItem) => {
    return (
      <li className="nav-item dropdown" key={`key_${naviagationItem.link || Math.random()}`}>
        <NavLink className="nav-link" to={naviagationItem.link}>
          {naviagationItem.title}
        </NavLink>

        <button
          id={naviagationItem.link}
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="dropdown-toggle dropdown-toggle-split"
        >
          <span className="visually-hidden">Toggle Dropdown</span>
        </button>

        <ul className="dropdown-menu" aria-labelledby={naviagationItem.link}>
          {naviagationItem.children?.map((child) => {
            return renderDropdownChild(child);
          })}
        </ul>
      </li>
    );
  };

  const renderNavigation = () => {
    return navigation?.map((navigationItem) => {
      if (navigationItem.children) {
        return renderDropdown(navigationItem);
      }

      return (
        <li className="nav-item" key={`key_${navigationItem.link || Math.random()}`}>
          <NavLink className="nav-link" to={navigationItem.link}>
            {navigationItem.title}
          </NavLink>
        </li>
      );
    });
  };

  const renderTopLink = (link) => {
    if (link.publicUrl?.includes("http") || link.url?.includes("http")) {
      return (
        <li key={link.title} className="nav-item">
          <a href={link.publicUrl || link.url} target={link.target} className="toplinks-link">
            {link.title}
          </a>
        </li>
      );
    }

    return (
      <li key={link.title} className="nav-item">
        <NavLink
          to={link.publicUrl || link.url}
          target={link.target}
          activeClassName="active"
          className="toplinks-link"
          // aria-current="false"
        >
          {link.title}
        </NavLink>
      </li>
    );
  };

  const renderSearchPanel = () => {
    // const activeTranslation = translation.find((lang) => lang.active === 1);

    return (
      <div id="search" className="navbar-search-collapse collapse w-100">
        <div className="navbar-search-form">
          {searchLabel && <Headline headerType="h5" headline={searchLabel} />}

          <form className="search" onSubmit={onSearchSubmit}>
            <div className="form-floating">
              <input
                id="searchInput"
                type="text"
                name="search"
                placeholder="Search"
                className="form-control search-input"
                value={searchValue}
                onChange={onSearchChange}
              />

              <label htmlFor="searchInput" className="form-label">
                {searchPlaceholder}
              </label>
            </div>
            <button className="search-button" type="submit" onClick={onSearchSubmit}>
              <SearchIcon />
            </button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <>
      <nav className="navbar navbar-expand-xxl navbar-light navbar-mp fixed-top">
        <div className="menu-subpages-with-search secondary">
          <div className="container d-flex">
            <div className="navbar-search-wrapper d-flex d-xxl-none">
              <button
                className="navbar-search"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#search"
                aria-controls="search"
              >
                <SearchIcon />
              </button>
            </div>

            <NavLink className="navbar-brand" to="/">
              <Image image={logo[0]} />
            </NavLink>

            <div className="me-3 d-none d-xxl-block">{renderLanguageswitch()}</div>

            <div className="d-none d-xxl-flex w-100">
              <div className="navbar-search-form me-3">
                {searchLabel && <Headline headerType="h5" headline={searchLabel} />}

                <form className="search" onSubmit={onSearchSubmit}>
                  <div className="form-floating">
                    <input
                      id="searchInput"
                      type="text"
                      name="search"
                      placeholder="Search"
                      className="form-control search-input"
                      value={searchValue}
                      onChange={onSearchChange}
                    />

                    <label htmlFor="searchInput" className="form-label">
                      {searchPlaceholder}
                    </label>
                  </div>
                  <button className="search-button" type="submit" onClick={onSearchSubmit}>
                    <SearchIcon />
                  </button>
                </form>
              </div>
            </div>

            <div className="navbar-buttons">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div className="toggler-open">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="toggler-close">
                  <i className="close" />
                </div>
              </button>
            </div>

            <div className="collapse navbar-collapse" id="navbarNav">
              <div className="navbar-collapse-inner">
                <div className="d-block d-xxl-none">{renderLanguageswitch()}</div>
                <div className="navbar-toplinks d-none d-xxl-flex">
                  <ul className="navbar-nav toplinks me-md-3">
                    <ul className="toplinks">
                      {topLinks.map((topLink) => renderTopLink(topLink.link))}
                    </ul>
                  </ul>
                </div>

                <ul className="navbar-nav d-flex d-xxl-none">{renderNavigation()}</ul>

                <div className="d-none d-xxl-flex">
                  <form className="links">
                    <LinkSwitcher
                      linkType={primaryLinkType}
                      link={primaryLink}
                      additionalClasses={`${
                        primaryLinkType.includes("btn") ? "btn-sm" : "link-sm"
                      } me-md-3`}
                    />

                    <span className="navbar-link">
                      <LinkSwitcher
                        linkType={secondaryLinkType}
                        link={secondaryLink}
                        additionalClasses={`${
                          secondaryLinkType.includes("btn") ? "btn-sm" : "link-sm"
                        }`}
                      />
                    </span>
                  </form>
                </div>

                <div className="navbar-toplinks d-flex d-xxl-none">
                  <ul className="navbar-nav toplinks">
                    <ul className="toplinks">
                      {[primaryLink, secondaryLink].map((link) => renderTopLink(link))}

                      {topLinks.map((topLink) => renderTopLink(topLink.link))}
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-pages-with-search secondary">
          <div className="container d-flex justify-content-center">
            <div className="d-flex d-xxl-none w-100">{renderSearchPanel()}</div>

            <div className="d-none d-xxl-flex">
              <ul className="navbar-nav">{renderNavigation()}</ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

SecondaryMenuSubPagesWithSearch.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryMenuSubPagesWithSearch;
