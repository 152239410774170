import React from "react";
import PropTypes from "prop-types";

import StandardDivider from "./StandardDivider";
import ComponentMissing from "../../Debug/ComponentMissing";

const DividerSwitcher = ({ layout, cols, alignment, outerClasses }) => {
  const properties = {
    cols,
    alignment,
    outerClasses,
  };

  function switchDivider() {
    switch (layout) {
      case "standard":
        return <StandardDivider properties={properties} />;
      default:
        return <ComponentMissing type="Divider" subType={layout} />;
    }
  }

  return switchDivider();
};

DividerSwitcher.propTypes = {
  layout: PropTypes.string,
  cols: PropTypes.string,
  alignment: PropTypes.string,
  outerClasses: PropTypes.string,
};

export default DividerSwitcher;
