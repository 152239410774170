import React, { useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import qs from "qs";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import FormElementSwitcher from "../../Atoms/FormElement/FormElementSwitcher";

import { postApi } from "../../../Utils/ApiUtils";

import { validate } from "../../../Utils/FunctionalUtils";

/**
 * create controlled formhandling as shown in react docs: https://reactjs.org/docs/forms.html
 */
const DefaultFormHandler = ({
  properties: { header, headerType, headerDisplayStyle, subline, teaser, form, uplink, animation },
}) => {
  const [formData, updateFormData] = useState(
    form.elements?.reduce((acc, item) => {
      if (item.type !== "submit" && item.type !== "GridRow") {
        acc[item.identifier] = item.defaultValue;
      }

      if (item.type === "GridRow") {
        item.elements?.forEach((gridItem) => {
          acc[gridItem.identifier] = gridItem.defaultValue;
        });
      }

      return acc;
    }, {}),
  );

  const validators = useRef(
    form.elements?.reduce((acc, item) => {
      if (item.type !== "submit" && item.type !== "GridRow") {
        if (item.validators) {
          acc[item.identifier] = item.validators.map((validator) => validator.identifier);
        }
      }

      if (item.type === "GridRow") {
        item.elements?.forEach((gridItem) => {
          if (gridItem.validators) {
            acc[gridItem.identifier] = gridItem.validators.map((validator) => validator.identifier);
          }
        });
      }

      return acc;
    }, {}),
  );

  const [validationErrors, setValidationErrors] = useState({});

  const [responseMessage, setResponseMessage] = useState(null);

  const [toggleableFields, setToggleableFields] = useState(
    form.elements?.reduce((acc, item) => {
      if (item.type !== "submit" && item.type !== "GridRow" && item.identifier.includes("__")) {
        const [name, target, value] = item.identifier.split("__");

        acc.push({
          identifier: item.identifier,
          type: item.type,
          name,
          target,
          hideField: value !== "false", // because "false" string is true I cannot just use !!
        });
      }

      // if any option value of a SingleSelect contains "__" consider it a toggleableField
      // CAN HAVE ONLY ONE VALUE LIKE THAT WITH CURRENT IMPLEMENTATION !!!
      if (
        item.type === "SingleSelect" &&
        Object.keys(item.properties.options).some((optionValue) => optionValue.includes("__"))
      ) {
        const [name, target, hideField] = Object.keys(item.properties.options)
          .filter((optionValue) => optionValue.includes("__"))[0]
          .split("__");

        acc.push({
          identifier: item.identifier,
          type: item.type,
          name,
          target,
          hideField: hideField !== "false", // because "false" string is true I cannot just use !!
        });
      }

      if (item.type === "GridRow" && item.identifier.includes("__")) {
        item.elements?.forEach((gridItem) => {
          const [name, target, hideField] = gridItem.identifier.split("__");

          acc.push({
            identifier: item.identifier,
            type: gridItem.type,
            name,
            target,
            hideField: hideField !== "false", // because "false" string is true I cannot just use !!
          });
        });
      }

      return acc;
    }, []),
  );

  // const [fieldsHidden, setFieldsHidden] = useState(true);

  const history = useHistory();

  const handleFormDataChange = useCallback(
    ({ target: { name, value, options, selectedIndex } }) => {
      updateFormData({
        ...formData,
        [name]: value,
      });

      setValidationErrors({
        ...validationErrors,
        [name]: null,
      });

      const newToggleableFields = toggleableFields.map((field) => {
        if (field.identifier === name) {
          // for single select
          if (field.type === "SingleSelect") {
            // if a toggler option selected - show field
            if (options[selectedIndex].dataset.toggler) {
              return { ...field, hideField: false };
            }

            // if not a toggler option selected - hide field
            return { ...field, hideField: true };
          }

          // for our standard logic
          return { ...field, hideField: !field.hideField };
        }

        return field;
      });

      setToggleableFields(newToggleableFields);
    },
    [formData, validationErrors, toggleableFields, setToggleableFields],
  );

  const validateFormData = useCallback(() => {
    const tempValidationErrors = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const field in validators.current) {
      if (Object.prototype.hasOwnProperty.call(validators.current, field)) {
        validators.current[field].forEach((validator) => {
          const validationErrorMessage = validate(formData[field], validator);

          if (validationErrorMessage) {
            tempValidationErrors[field] = validationErrorMessage;
          }
        });
      }
    }

    setValidationErrors(tempValidationErrors);

    return Object.keys(tempValidationErrors).length;
  }, [formData]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const hasValidationErrors = validateFormData();

      if (hasValidationErrors) {
        return;
      }

      const targetUrl = uplink.url;

      try {
        const { data } = await postApi(
          targetUrl,
          qs.stringify({ tx_form_formframework: { [form.id]: formData } }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" },
          },
        );

        const response = data.content.colPos0.find(
          (contentElement) => contentElement.type === "form_formframework",
        )?.content.form.api;

        if (response && response.status === "failure") {
          throw Error(Object.values(response.errors)[0]);
        }

        const responseHandler = form.api.actionAfterSuccess;

        if (responseHandler) {
          switch (responseHandler.type) {
            case "redirect":
              history.push(responseHandler.content);
              return;
            case "message":
              setResponseMessage(responseHandler.content);
              return;
            default:
              return;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      uplink.url,
      form.id,
      form.api.actionAfterSuccess,
      formData,
      history,
      validateFormData,
      setResponseMessage,
    ],
  );

  return (
    <div className="container form-framework default">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}
        </div>
      </div>

      {form.elements.length > 0 && !responseMessage && (
        <div className="row justify-content-center mt-small">
          <div className="col-md-10">
            <form onSubmit={handleSubmit} method="post" name={form.id}>
              <div className="row g-4">
                {form.elements.map((item) => {
                  return (
                    <FormElementSwitcher
                      key={`key_${item.identifier || Math.random()}`}
                      item={item}
                      formData={formData}
                      validationErrors={validationErrors}
                      onChangeHandler={handleFormDataChange}
                      toggleableFields={toggleableFields}
                      animation={animation}
                    />
                  );
                })}
              </div>
            </form>
          </div>
        </div>
      )}

      {responseMessage && (
        <div className="row justify-content-center mt-small">
          <div className="col-md-10">
            <div className="alert alert-success">{responseMessage}</div>
          </div>
        </div>
      )}
    </div>
  );
};

DefaultFormHandler.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default DefaultFormHandler;
