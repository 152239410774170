import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import Fancybox from "../../Atoms/FancyBox";

import { REACT_APP_API_BASE_URL } from "../../../Constants/APIConstants";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const CardsSliderGallery = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    galleryItems,
    columnsPerRow,
    lightbox,
    animation,
  },
}) => {
  const sliderRef = useRef(null);

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: +columnsPerRow.col_lg,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: +columnsPerRow.col_md,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: +columnsPerRow.col_sm,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: +columnsPerRow.col_xs,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const renderGallery = useCallback(() => {
    return Object.values(galleryItems.columns).map((column) => {
      return (
        <div
          key={`key_${column.properties.title || Math.random()}`}
          className="slider-item d-flex justify-content-center"
        >
          <div className="card">
            <div className="card-image">
              {lightbox ? (
                <Fancybox>
                  <a href={column.publicUrl} data-fancybox={id}>
                    {column.properties.videoThumbnail ? (
                      <Image
                        image={{
                          // eslint-disable-next-line max-len
                          publicUrl: `${REACT_APP_API_BASE_URL}/${column.properties.videoThumbnail}`,
                          properties: { alternative: column.properties.filename },
                        }}
                      />
                    ) : (
                      <Image image={column} />
                    )}
                  </a>
                </Fancybox>
              ) : (
                <Image image={column} />
              )}
            </div>

            <div className="card-body">
              <Preline preline={column.properties.subline} additionalClasses="color-static" />

              <Headline
                headerType={column.properties.headerType}
                headline={column.properties.title}
                additionalClasses="color-static"
              />

              {column.properties.description && (
                <div className="mt-xsmall">
                  <Text
                    textType="text"
                    text={column.properties.description}
                    additionalClasses="color-static"
                  />
                </div>
              )}

              {column.properties.link && (
                <div className="mt-xxsmall" data-aos={animation.links.default}>
                  <div className="links">
                    <LinkSwitcher
                      linkType={column.properties.linkType}
                      link={column.properties.link}
                      additionalClasses="color-static"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  }, [galleryItems, lightbox, id, animation]);

  return (
    <div className="container gallery cards-slider">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline
            headerType={headerType}
            headerStyle={headerDisplayStyle}
            headline={header}
            additionalClasses="upper"
          />
        </div>
      </div>

      {Object.values(galleryItems.columns).length > 0 && (
        <div className="row mt-small">
          <div className="col-12">
            <div ref={sliderRef} id={`gallery-cards-slider-${id}`}>
              <SlickSlider {...settings.current}>{renderGallery()}</SlickSlider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CardsSliderGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default CardsSliderGallery;
