import React from "react";
import PropTypes from "prop-types";

import Image from "../../Atoms/Image";

const FullWidthOnlyImage = ({ properties: { image, maxHeight } }) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "0",
  };

  return (
    <div
      className="only-image full-width"
      style={{ height: +maxHeight || image?.properties.dimensions.height }}
    >
      <Image image={image} style={inlineStyles} />
    </div>
  );
};

FullWidthOnlyImage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default FullWidthOnlyImage;
