import React from "react";
import PropTypes from "prop-types";

import StandardDownloads from "./StandardDownloads";
import SimpleDownloads from "./SimpleDownloads";
import ComponentMissing from "../../Debug/ComponentMissing";

const DownloadsSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  downloads,
  columnsPerRow,
  cols,
  alignment,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    downloads,
    columnsPerRow,
    cols,
    alignment,
  };

  function switchDownloads() {
    switch (layout) {
      case "standard":
        return <StandardDownloads properties={properties} />;
      case "simpleCard":
        return <SimpleDownloads properties={properties} />;
      default:
        return <ComponentMissing type="Downloads" subType={layout} />;
    }
  }

  return switchDownloads();
};

DownloadsSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  downloads: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  columnsPerRow: PropTypes.instanceOf(Object),
  cols: PropTypes.string,
  alignment: PropTypes.string,
};

export default DownloadsSwitcher;
