import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const WallNewsListItem = ({ news, linkType, linkTitle, hideDate, animation }) => {
  const inlineStyles = {
    backgroundImage: `url(${news.media?.[0]?.images.listViewFeaturedImage.publicUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "75%",
  };

  return (
    <div className="col d-flex">
      <div className="card w-100">
        {news.media?.[0] && (
          <div className="mb-small">
            <div className="card-image">
              <Image imageType="background" image={news.media?.[0]} style={inlineStyles} />
            </div>
          </div>
        )}

        {!hideDate && <Preline preline={news.datetime} />}

        {news.slug && (
          <NavLink to={news.slug}>
            <Headline headerType="h4" headline={news.title} />
          </NavLink>
        )}

        {news.teaser && (
          <div className="mt-xsmall">
            <Text textType="text" text={`${news.teaser.slice(0, 350)}...`} />
          </div>
        )}

        {news.slug && (
          <div className="mt-small" data-aos={animation.links.default}>
            <div className="links">
              <LinkSwitcher linkType={linkType} link={{ publicUrl: news.slug, title: linkTitle }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

WallNewsListItem.propTypes = {
  news: PropTypes.instanceOf(Object),
  linkType: PropTypes.string,
  linkTitle: PropTypes.string,
  hideDate: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default WallNewsListItem;
