import React from "react";
import PropTypes from "prop-types";

import Text from "../Text";

const StaticText = ({ label }) => {
  return (
    <div className="col-12">
      <Text text={label} textType="html" />
    </div>
  );
};

StaticText.propTypes = {
  // id: PropTypes.string,
  label: PropTypes.string,
  // animation: PropTypes.instanceOf(Object),
};

export default StaticText;
