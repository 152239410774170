/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";

const MultiSelect = ({
  id,
  label,
  // formData,
  validationErrors,
  onChangeHandler,
  defaultValue,
  options,
  additionalClasses,
}) => {
  const selectOptions = Object.keys(options).map((key) => ({
    label: key,
    value: options[key],
  }));

  selectOptions.unshift({ label: defaultValue, value: defaultValue, isDisabled: true });

  const styles = {
    indicatorSeparator: () => {},
    control: (base, { isFocused }) => ({
      ...base,
      fontSize: 18,
      fontWeight: 300,
      borderRadius: "6px",
      borderColor: validationErrors ? "#dc3545" : isFocused ? "#81cdee" : "#ced4da",
      borderWidth: "1px",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      boxShadow: isFocused
        ? validationErrors
          ? "0 0 0 0.25rem rgba(220, 53, 69, 0.25)"
          : "inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(2, 155, 221, 0.25)"
        : "none",
      cursor: "pointer",
      backgroundImage: validationErrors
        ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E")`
        : "none",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right calc(0.375em + 2.55rem) center",
      backgroundSize: "calc(0.75em + 0.5rem) calc(0.75em + 0.5rem)",
      "&:hover": {
        borderColor: validationErrors ? "#dc3545" : isFocused ? "#81cdee" : "#ced4da",
      },
      // "&:focus": {
      //   borderColor: "#81cdee",
      //   boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(2, 155, 221, 0.25)",
      // },
      // "&:active": {
      //   borderColor: "#81cdee",
      //   boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(2, 155, 221, 0.25)",
      // },
    }),
    menu: (base) => ({
      ...base,
      fontSize: 16,
      fontWeight: 400,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      cursor: "pointer",
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isSelected ? "#00c5cd" : isFocused ? "#e9ecef" : "#ffffff",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "rgba(0,0,0,0.75)",
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "scale(0.915) rotate(180deg)" : "scale(0.915)",
      paddingRight: "6px",
    }),
    placeholder: (base) => {
      return {
        ...base,
        color: "#000",
      };
    },
  };

  return (
    <div className={`col-12 ${additionalClasses}`.trim()}>
      <Select
        closeMenuOnSelect={false}
        // defaultValue={selectOptions[0]}
        isMulti
        id={id}
        name={id}
        styles={styles}
        // value={formData[id]}
        options={selectOptions}
        placeholder={label}
        onChange={(e) => {
          const normalizedEvent = {
            target: {
              name: id,
              value: e.map((el) => el.value),
            },
          };

          onChangeHandler(normalizedEvent);
        }}
        className={`color-static ${validationErrors ? "is-invalid" : ""}`.trim()}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  // formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.string,
  onChangeHandler: PropTypes.func,
  defaultValue: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  additionalClasses: PropTypes.string,
};

MultiSelect.defaultProps = {
  additionalClasses: "",
};

export default MultiSelect;
