import React from "react";
import PropTypes from "prop-types";

import WallFeatures from "./WallFeatures";
import SliderFeatures from "./SliderFeatures";
import HorizontalFeatures from "./HorizontalFeatures";
import ComponentMissing from "../../Debug/ComponentMissing";

const FeaturesSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  id,
  features,
  columnsPerRow,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    features,
    columnsPerRow,
    animation,
  };

  function switchFeatures() {
    switch (layout) {
      case "wall":
        return <WallFeatures properties={properties} />;
      case "carousel":
        return <SliderFeatures properties={properties} />;
      case "horizontal":
        return <HorizontalFeatures properties={properties} />;
      default:
        return <ComponentMissing type="Features" subType={layout} />;
    }
  }

  return switchFeatures();
};

FeaturesSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  id: PropTypes.number,
  features: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  columnsPerRow: PropTypes.instanceOf(Object),
};

export default FeaturesSwitcher;
