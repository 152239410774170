import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const MediumTAI = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    image,
    backgroundImage,
    maxHeight,
    mirrored,
    fixedImage,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
  };

  const getImageContainer = useCallback(() => {
    return (
      <div className="col-md-5 order-1 order-md-0 mb-4 mb-md-0">
        <Image image={image} animation={animation.image.default} />
      </div>
    );
  }, [image, animation]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-md-7 order-2 order-md-0">
        <Preline preline={subline} />

        <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

        {teaser && (
          <div className="mt-xsmall">
            <Text textType="html" text={teaser} />
          </div>
        )}

        {(headerLink || secondaryLink) && (
          <div className="mt-small" data-aos={animation.links.default}>
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                // additionalClasses="btn-lg rounded-pill"
              />

              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        )}
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse align-items-center mirrored">
          {getImageContainer()}
          {getTextContainer()}
        </div>
      );
    }

    return (
      <div className="row align-items-center">
        {getImageContainer()}
        {getTextContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer]);

  return (
    <div
      className={`teaser-and-image medium ${fixedImage ? "fixed" : ""}`.trim()}
      style={{ minHeight: +maxHeight || backgroundImage?.properties.dimensions.height }}
    >
      <Image image={backgroundImage} style={inlineStyles} />

      <div className="container">{getContent()}</div>
    </div>
  );
};

MediumTAI.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MediumTAI;
