/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
// import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

/**
 * full size component
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GoogleMapComponent = withScriptjs(
  withGoogleMap(
    ({ styleOptions, properties: { markers, icon, centerLat, centerLng, defaultZoom } }) => {
      const [activeInfo, setActiveInfo] = useState("");

      let defaultCenter = { lat: -34.397, lng: 150.644 };

      if (centerLat && centerLng) {
        defaultCenter = { lat: +centerLat, lng: +centerLng };
      }

      let markerIcon = null;

      if (icon) {
        markerIcon = { url: icon.publicUrl };
      }

      const renderMarkers = () => {
        return markers.map((marker) => {
          return (
            <Marker
              key={`key_${marker.latitude}_${marker.longitude}`}
              icon={markerIcon}
              position={{ lat: +marker.latitude, lng: +marker.longitude }}
              onClick={() => setActiveInfo(`${marker.latitude}_${marker.longitude}`)}
            >
              {activeInfo === `${marker.latitude}_${marker.longitude}` && (
                <InfoWindow onCloseClick={() => setActiveInfo("")}>
                  <>
                    <Headline headerType="h5" headline={marker.header} />

                    {marker.info && (
                      <div className="mt-xsmall">
                        <Text textType="html" text={marker.info} />
                      </div>
                    )}

                    {marker.link && (
                      <div className="mt-xsmall">
                        <a href={marker.link.url} className="link-primary">
                          {marker.link.title}
                        </a>
                        {/* <LinkSwitcher linkType="link-primary" link={marker.link} /> */}
                      </div>
                    )}
                  </>
                </InfoWindow>
              )}
            </Marker>
          );
        });
      };

      return (
        <GoogleMap
          defaultZoom={+defaultZoom}
          defaultCenter={defaultCenter}
          defaultOptions={{ styles: styleOptions }}
        >
          {renderMarkers()}
        </GoogleMap>
      );
    },
  ),
);

const StandardGoogleMaps = (props) => {
  return (
    <div className="google-maps standard">
      <div className="row">
        <div className="col-12">
          <GoogleMapComponent {...props} />
        </div>
      </div>
    </div>
  );
};
export default StandardGoogleMaps;
