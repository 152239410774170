import React from "react";
import PropTypes from "prop-types";

import StandardQuotes from "./StandardQuotes";
import SimpleQuotes from "./SimpleQuotes";
import ComponentMissing from "../../Debug/ComponentMissing";

const Quotes = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  id,
  quoteItems,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    quoteItems,
    animation,
  };

  function switchQuotes() {
    switch (layout) {
      case "standard":
        return <StandardQuotes properties={properties} />;
      case "simple":
        return <SimpleQuotes properties={properties} />;
      default:
        return <ComponentMissing type="Quotes" subType={layout} />;
    }
  }

  return switchQuotes();
};

Quotes.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  id: PropTypes.number,
  quoteItems: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  animation: PropTypes.instanceOf(Object),
};

export default Quotes;
