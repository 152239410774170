export const mapWrapperClasses = () => {
  return "container";
};

export const plaeholder = () => {
  return "placeholder";
};

export const scrollToAnchor = () => {
  // scroll to anchor if exists
  const { hash } = window.location;

  if (hash !== "") {
    // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
    const decodedHash = window.decodeURIComponent(hash).split("=")[0];

    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = decodedHash.replace("#", "");
      const element = document.getElementById(id);

      // if (element) element.scrollIntoView({ behavior: "smooth" });
      if (element) {
        const elementOffset = element.offsetTop;
        const navigationElement = document.getElementById("navigation").querySelector(".navbar");
        const navigationHeight = navigationElement.offsetHeight;

        window.scroll({ top: elementOffset - navigationHeight, left: 0, behavior: "smooth" });
      }
    }, 250);
  }
};

export const getAnimation = (animationLayout, mirrored = "0") => {
  switch (animationLayout) {
    case "type1":
      return {
        image: {
          default: +mirrored ? "fade-right" : "fade-left",
          contactCard: "fade-up-right",
          factSheet: "fade-up",
        },
        links: {
          default: "fade-up",
        },
        list: {
          default: "fade-up",
        },
      };
    case "type2":
      return {
        image: {
          default: +mirrored ? "fade-up-right" : "fade-up-left",
          contactCard: "fade-down-right",
          factSheet: "fade-down",
        },
        links: {
          default: "fade-down",
        },
        list: {
          default: "fade-up",
        },
      };
    default:
      return {
        image: {
          default: "none",
          contactCard: "none",
          factSheet: "none",
        },
        links: {
          default: "none",
        },
        list: {
          default: "none",
        },
      };
  }
};
