import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";

const SimpleDownloads = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    downloads,
    columnsPerRow,
    cols,
    alignment,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const renderDownloads = useCallback(() => {
    return downloads.map((download) => {
      return (
        <a
          href={download.publicUrl}
          className="col d-flex"
          key={`key_${download.publicUrl || Math.random()}`}
        >
          <div className="card w-100">
            <div className="card-body">
              <Headline
                headerType="h5"
                headline={download.properties.title}
                additionalClasses="color-static"
              />

              <Text text={download.properties.description} additionalClasses="color-static small" />
            </div>
          </div>
        </a>
      );
    });
  }, [downloads]);

  const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

  return (
    <div className="container downloads simple">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {downloads.length > 0 && (
        <div className="mt-small">
          <div className={getClasses()}>
            <div className={`col-md-${cols}`}>
              <div
                className={`row 
                        row-cols-${colXs} 
                        row-cols-sm-${colSm} 
                        row-cols-md-${colMd} 
                        row-cols-lg-${colLg} 
                        g-4`.replace(/\n\s+/g, "")}
              >
                {renderDownloads()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SimpleDownloads.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SimpleDownloads;
