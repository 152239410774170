import React from "react";
import ReactDOM from "react-dom";
// import TagManager from "react-gtm-module";
import smoothScroll from "smoothscroll-polyfill";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faGooglePlay,
  faAppStore,
  faXing,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import RootContainer from "./Containers/RootContainer";

library.add(
  faTwitter,
  faFacebook,
  faLinkedin,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faGooglePlay,
  faAppStore,
  faXing,
  faFacebookF,
);

dom.watch();

smoothScroll.polyfill();

// const { REACT_APP_GOOGLE_TAG_MANAGER_ID } = process.env;

// if (REACT_APP_GOOGLE_TAG_MANAGER_ID) {
//   const tagManagerArgs = {
//     gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID,
//   };
//   TagManager.initialize(tagManagerArgs);
// }

const rootElement = document.getElementById("root");

// never delete this, we need it for prerendering
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<RootContainer />, rootElement);
} else {
  ReactDOM.render(<RootContainer />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
