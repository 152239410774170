import React from "react";
import PropTypes from "prop-types";

import StandardFactSheet from "./StandardFactSheet";
import HorizontalFactSheet from "./HorizontalFactSheet";
import ComponentMissing from "../../Debug/ComponentMissing";

const FactSheetSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  teaser,
  facts,
  columnsPerRow,
  cols,
  alignment,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    teaser,
    facts,
    columnsPerRow,
    cols,
    alignment,
    animation,
  };

  function switchFactSheet() {
    switch (layout) {
      case "standard":
        return <StandardFactSheet properties={properties} />;
      case "horizontal":
        return <HorizontalFactSheet properties={properties} />;
      default:
        return <ComponentMissing type="FactSheet" subType={layout} />;
    }
  }

  return switchFactSheet();
};

FactSheetSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  facts: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  columnsPerRow: PropTypes.instanceOf(Object),
  cols: PropTypes.string,
  alignment: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default FactSheetSwitcher;
