import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardHeader = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    cols,
    alignment,
    animation,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  return (
    <div className="container header standard">
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>
          {headerLink && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  additionalClasses="mb-xxsmall"
                />
              </div>
            </div>
          )}
          <Preline preline={subline} />
          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}
          {secondaryLink && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={secondaryLinkType}
                  link={secondaryLink}
                  additionalClasses="mb-xxsmall"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardHeader.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardHeader;
