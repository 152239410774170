import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardTAB = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    bodytext,
    mirrored,
    animation,
  },
}) => {
  const getTextContainer = useCallback(() => {
    return (
      <div className="col-md-5 order-1 order-md-0">
        <Preline preline={subline} />

        <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

        {teaser && (
          <div className="mt-xsmall">
            <Text textType="html" text={teaser} />
          </div>
        )}

        {(headerLink || secondaryLink) && (
          <div className="mt-small d-none d-lg-block" data-aos={animation.links.default}>
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                additionalClasses="mb-xxsmall"
              />
              <LinkSwitcher
                linkType={secondaryLinkType}
                link={secondaryLink}
                additionalClasses="mb-xxsmall"
              />
            </div>
          </div>
        )}
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getBulletsContainer = useCallback(() => {
    return (
      <div className="col-md-6 order-2 order-md-0">
        <ul className="list mt-4 mt-md-0">
          {bodytext?.map((bullet) => {
            return <li key={`key_${bullet || Math.random()}`}>{bullet}</li>;
          })}
        </ul>

        {(headerLink || secondaryLink) && (
          <div className="mt-xlarge d-lg-none" data-aos={animation.links.default}>
            <div className="links">
              <LinkSwitcher linkType={headerLinkType} link={headerLink} />
              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        )}
      </div>
    );
  }, [bodytext, headerLink, headerLinkType, secondaryLink, secondaryLinkType, animation]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse mirrored">
          {getTextContainer()}
          {getBulletsContainer()}
          <div className="col-1" />
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-1" />
        {getTextContainer()}
        {getBulletsContainer()}
      </div>
    );
  }, [mirrored, getBulletsContainer, getTextContainer]);

  return <div className="container text-and-bullets standard">{getContent()}</div>;
};

StandardTAB.propTypes = {
  properties: PropTypes.instanceOf(Object),
};
export default StandardTAB;
