import React from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const BannerTAI = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    backgroundImage,
    maxHeight,
    fixedImage,
    animation,
  },
}) => {
  const inlineStyles = {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
  };

  return (
    <div
      className={`teaser-and-image banner ${fixedImage ? "fixed" : ""}`.trim()}
      style={{ minHeight: +maxHeight || backgroundImage?.properties.dimensions.height }}
    >
      <Image image={backgroundImage} style={inlineStyles} />

      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-10">
            <Preline preline={subline} />

            <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

            {teaser && (
              <div className="mt-xsmall">
                <Text textType="html" text={teaser} />
              </div>
            )}

            {(headerLink || secondaryLink) && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BannerTAI.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default BannerTAI;
