import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import SecondaryAccordionItem from "./SecondaryAccordionItem";

const SecondaryAccordion = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    id,
    accordionItems,
    animation,
  },
}) => {
  const renderAccordion = useCallback(() => {
    return accordionItems.map((accordionItem) => {
      return (
        <SecondaryAccordionItem
          key={`key_${accordionItem.id || Math.random()}`}
          id={`accordion-${id}`}
          accordionItem={accordionItem}
        />
      );
    });
  }, [accordionItems, id]);

  return (
    <div className="container accordion secondary">
      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {accordionItems.length > 0 && (
        <div className="row justify-content-center mt-small">
          <div className="col-md-10">
            <div className="accordion-flush" id={`accordion-${id}`}>
              {renderAccordion()}
            </div>
          </div>
        </div>
      )}

      {headerLink && (
        <div className="row justify-content-center mt-small" data-aos={animation.links.default}>
          <div className="col-md-10">
            <div className="links">
              <LinkSwitcher linkType={headerLinkType} link={headerLink} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SecondaryAccordion.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryAccordion;
